const initMail = () => {
  const mailLinks = {
    'DPO': {
      coded: 'vWX@Dd7RO.2Tu',
      key: 'mo8vbX7czkFpKuTC0Pjx2SAei5qy6rWYI3BQdwhtUaHMV9lnfG4ZDNOsLJ1RgE',
    },
  };

  for (const [id, { coded, key }] of Object.entries(mailLinks)) {
    const shift = coded.length;
    let link = '';
    let ltr;
    for (let i = 0; i < coded.length; i++) {
      if (key.indexOf(coded.charAt(i)) === -1) {
        ltr = coded.charAt(i);
        link += (ltr);
      }
      else {
        ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length;
        link += (key.charAt(ltr));
      }
    }

    const mailLink = document.getElementById(id);
    mailLink.setAttribute('href', `mailto:${link}`);
    mailLink.innerHTML = link;
  }
};

export default initMail;
