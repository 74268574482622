import loopImages from './image-looper';

export default function initHeroSwitcher() {
    var visibleHero = 0,
        images = document.getElementsByClassName('section-image'),
        imageContainer = document.querySelectorAll('.hero-images')[0];

    if (imageContainer) {
        loopImages(images, visibleHero);
    }
}

