import loopImages from './image-looper'

export default function initRecruitmentHeroSwitcher() {
    let images = document.getElementsByClassName('section-image');

    if (window.location.href.indexOf('/recruitment/search') > -1) {
        if (window.location.href.indexOf('department=') > -1) {
            let department = getParameterByName('department');
            updateDepartmentImage(department);
        }

        document.addEventListener('change', function(event) {
            if (event.target.name === '/department') {
                updateDepartmentImage(event.target.value);
            }
        });
    }

    function updateDepartmentImage(department) {
        switch (department) {
            case '1':  // Engineering
            case '10': // IT
            case '11': // Production
            case '33': // Quality
                loopImages(images, 1, false);
                break;
            case '12': // HR
            case '8':  // Marketing
            case '13': // Finance
                loopImages(images, 2, false);
                break;
            case '6': // Facilities
                loopImages(images, 3, false);
                break;
            default:
                loopImages(images, 0, false);
                break;
        }
    }

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

}