import initHeader from './header'
import initHeroSwitcher from './helpers/heroSwitcher'
import initRecruitmentHeroSwitcher from './helpers/recruitment-search-hero-switcher'
import initMail from './helpers/mail'
import { initModals } from '@tesla/design-system'
import '../sass/base.scss'

// Initialize application if DOM ready, else wait for DOM content to load
if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
    initApp()
} else {
    document.addEventListener('DOMContentLoaded', initApp)
}

function initApp() {
    // Initialize header
    initHeader();

    // Initialize hero switcher
    initHeroSwitcher();

    // Initialize recruitment hero switcher
    initRecruitmentHeroSwitcher();

    // Initialize modals
    initModals({});

    // Initialize mail links
    initMail();
}
