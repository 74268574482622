export default function loopImages(images, visibleHero, loop = true) {
    for (var i = 0; i < images.length; i++) {
        if (i === visibleHero) {
            images[i].classList.add("visible");
        }
        else {
            images[i].classList.remove("visible");
        }
    }

    if (visibleHero < images.length-1) {
        visibleHero = visibleHero + 1;
    }
    else {
        visibleHero = 0;
    }

    if (loop) {
        setTimeout(function(){loopImages(images, visibleHero)}, 4000);
    }
}
